<template>
  <v-container class="pa-4">
    <Substipulator
      :contractId="$route.params.id"
      @sub="subSelected($event)"
      v-if="hasSub"
    />

    <template v-if="contract.situation !== 'Cancelado'">
      <v-form
        @submit.prevent="save"
        ref="form"
        lazy-validation
        v-model="valid"
        v-permission="{
          roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
          operator: '==='
        }"
      >
        <v-row dense>
          <v-col cols="12" xl="10" lg="10" md="10" sm="6">
            <v-file-input
              id="file"
              name="file"
              label="Arquivo"
              dense
              outlined
              required
              accept=".pdf,.doc,.docx,.xlsx,.xls"
              :rules="[required]"
              v-model="model.file"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="2" sm="2" class="text-right">
            <BaseButton
              id="btn-save"
              name="btn-save"
              type="submit"
              color="primary"
              title="Adicionar"
              height="40"
              :disabled="!valid"
            />
          </v-col>
        </v-row>

        <v-divider />
      </v-form>
    </template>

    <BaseTableList title="Listagem de documentos">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  class="mr-2"
                  small
                  @click="download(item)"
                >
                  fa-solid fa-download
                </v-icon>
              </template>
              <span>Baixar arquivo</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  small
                  @click="remove(item)"
                  v-if="contract.situation !== 'Cancelado'"
                  v-permission="{
                    roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                    operator: '==='
                  }"
                >
                  fa-regular fa-trash-can
                </v-icon>
              </template>
              <span>Excluir</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>

    <v-divider class="my-5" />

    <v-row>
      <v-col class="text-right">
        <BaseButton
          id="btn-back"
          name="btn-back"
          title="Voltar"
          color="primary"
          outlined
          @click="back"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { downloadFile } from '@/helpers/download';
import { formatDate } from '@/helpers/formatting';
import { showMessage, confirmMessage } from '@/helpers/messages';
import { mapGetters } from 'vuex';
import { ContractService } from '@/services/api/contracts';

export default {
  mixins: [rulesMixin],

  components: {
    Substipulator: () => import('./commons/substipulator')
  },

  props: {
    productType: {
      type: String
    },
    customerId: {
      type: String
    },
    hasSub: {
      type: Boolean
    }
  },

  data: () => ({
    items: [],
    totalRecords: 0,
    substipulator: null,
    valid: true,
    options: {},
    model: {
      file: null
    },
    headers: [
      { text: 'Data de anexo', value: 'createdAt' },
      { text: 'Nome do arquivo', value: 'name' },
      { text: '', value: 'action' }
    ]
  }),

  computed: {
    ...mapGetters({
      contract: 'contracts/getContractById'
    })
  },

  methods: {
    formatDate,

    async search() {
      try {
        const { page, itemsPerPage } = this.options;
        const contractService = new ContractService();
        const { status, data } = await contractService.getDocuments({
          page,
          take: itemsPerPage,
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const contractService = new ContractService();
        const { status } = await contractService.uploadDocument({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId,
          ...this.model
        });

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.$refs.form.reset();
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async remove(item) {
      const result = await confirmMessage(
        'Atenção!',
        'Deseja realmente excluir o registro selecionado?'
      );

      if (result) {
        try {
          const contractService = new ContractService();
          const { status } = await contractService.deleteDocument({
            contractId: this.$route.params.id,
            customerId: this.substipulator
              ? this.substipulator
              : this.customerId,
            ...item
          });

          if (status === 204) {
            showMessage('success', 'Operação realizada com sucesso');
            this.search();
          }
        } catch (error) {
          throw new Error(error);
        }
      }
    },

    async download(item) {
      try {
        const contractService = new ContractService();
        const { status, data } = await contractService.downloadDocument({
          contractId: this.$route.params.id,
          customerId: this.substipulator ? this.substipulator : this.customerId,
          ...item
        });

        if (status === 200) {
          const { fileContent, fileName } = data;
          downloadFile(fileContent, fileName);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    back() {
      this.$emit('back');
    },

    subSelected(event) {
      this.substipulator = event;

      this.search();
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
