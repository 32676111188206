var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-4"},[(_vm.hasSub)?_c('Substipulator',{attrs:{"contractId":_vm.$route.params.id},on:{"sub":function($event){return _vm.subSelected($event)}}}):_vm._e(),(_vm.contract.situation !== 'Cancelado')?[_c('v-form',{directives:[{name:"permission",rawName:"v-permission",value:({
        roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
        operator: '==='
      }),expression:"{\n        roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n        operator: '==='\n      }"}],ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xl":"10","lg":"10","md":"10","sm":"6"}},[_c('v-file-input',{attrs:{"id":"file","name":"file","label":"Arquivo","dense":"","outlined":"","required":"","accept":".pdf,.doc,.docx,.xlsx,.xls","rules":[_vm.required]},model:{value:(_vm.model.file),callback:function ($$v) {_vm.$set(_vm.model, "file", $$v)},expression:"model.file"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"2"}},[_c('BaseButton',{attrs:{"id":"btn-save","name":"btn-save","type":"submit","color":"primary","title":"Adicionar","height":"40","disabled":!_vm.valid}})],1)],1),_c('v-divider')],1)]:_vm._e(),_c('BaseTableList',{attrs:{"title":"Listagem de documentos"},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('v-data-table',{attrs:{"dense":"","disable-sort":"","item-key":"id","headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalRecords,"items-per-page":10,"footer-props":{
          itemsPerPageOptions: [10, 20, 50, 100],
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'chevron_left',
          nextIcon: 'chevron_right'
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:`item.action`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.download(item)}}},on),[_vm._v(" fa-solid fa-download ")])]}}],null,true)},[_c('span',[_vm._v("Baixar arquivo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.contract.situation !== 'Cancelado')?_c('v-icon',_vm._g({directives:[{name:"permission",rawName:"v-permission",value:({
                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
                  operator: '==='
                }),expression:"{\n                  roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],\n                  operator: '==='\n                }"}],attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_vm._v(" fa-regular fa-trash-can ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}}],null,true)})]},proxy:true}])}),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"id":"btn-back","name":"btn-back","title":"Voltar","color":"primary","outlined":""},on:{"click":_vm.back}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }